import 'ress';
import 'core-js/stable';
import '../scss/style.scss';

document.addEventListener('DOMContentLoaded', () => {
	const hamburger = document.querySelector('.bl_headerHum');
	const nav = document.getElementById('js-nav');
	const { body } = document;

	// メニューを開く・閉じる関数
	const toggleMenu = () => {
		nav.classList.toggle('is-open');
		body.classList.toggle('no-scroll');
		hamburger.classList.toggle('active'); // ハンバーガーアイコンの状態を切り替え
	};

	// メニューを閉じる関数
	const closeMenu = () => {
		nav.classList.remove('is-open');
		nav.classList.add('is-close'); // メニューを閉じる際に 'is-close' クラスを追加
		body.classList.remove('no-scroll');
		hamburger.classList.remove('active'); // メニューを閉じるときに 'active' クラスを削除
	};

	// ハンバーガーメニューのクリックでメニューを開く・閉じる
	hamburger.addEventListener('click', toggleMenu);

	// メニューの各リンクにイベントリスナーを追加
	const menuLinks = document.querySelectorAll('#js-nav a');
	menuLinks.forEach((link) => {
		link.addEventListener('click', (event) => {
			// デフォルトのリンク動作を防止しない
			closeMenu();
		});
	});

	// メニュー外をクリックして閉じるためのイベントリスナーを追加
	nav.addEventListener('click', (event) => {
		// クリックされた要素がnav自体の場合のみクローズ処理を実行
		if (event.target === nav) {
			closeMenu();
		}
	});
});

// スムーススクロール
const offset = 50;
document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
	anchor.addEventListener('click', (e) => {
		e.preventDefault();
		const href = anchor.getAttribute('href');
		const target = document.getElementById(href.replace('#', ''));
		const targetPosition = target.getBoundingClientRect().top + window.pageYOffset - offset;

		window.scrollTo({
			top: targetPosition,
			behavior: 'smooth',
		});
	});
});
